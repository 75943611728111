import axios from "axios";
import store from "@/store/store";
const config = require("@/config/config");

export default (useConfigServer) => {
  if (useConfigServer !== undefined) {
    return axios.create({
      baseURL: `${config.server.https == 0 ? "http" : "https"}://${
        config.server.host
      }:${config.server.port}/`,
      headers: {
        //When registered or loged in the user will be given a token, this token will be placed on the header for any
        //request to the API by the below code. This way we guarantee the requester has permission to make the request.
        Authorization: `Bearer ${store.state.token}`,
      },
    });
  } else {
    return axios.create({
      baseURL: `${store.state.selectedServer}/`,
      headers: {
        //When registered or loged in the user will be given a token, this token will be placed on the header for any
        //request to the API by the below code. This way we guarantee the requester has permission to make the request.
        Authorization: `Bearer ${store.state.token}`,
      },
    });
  }
};
